
  import { IUserProfile } from "@/interfaces";
  import { dispatchConfirm, dispatchResendConfirm } from "@/store/main/actions";
  import { readUserProfile } from "@/store/main/getters";
  import { Vue, Component, Watch } from "vue-property-decorator";

  @Component
  export default class Confirm extends Vue {
    disabled = false;

    public async mounted() {
      const token = this.$router.currentRoute.query.token as string;
      if (token) {
        await dispatchConfirm(this.$store, { token });
      }
    }

    get userProfile() {
      return readUserProfile(this.$store);
    }

    @Watch("userProfile")
    public async onUserProfileChange(newUserProfile: IUserProfile | false) {
      if (newUserProfile && newUserProfile.is_active) {
        this.$router.push("/");
      }
    }

    async resendEmail() {
      if (this.userProfile) {
        this.disabled = true;
        await dispatchResendConfirm(this.$store, { email: this.userProfile.email });
      }
    }
  }
